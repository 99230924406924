import { aeroApiMng } from '/src/plugins/axios.js';

const startProcess = ({ controllerName, params }) => {
  return aeroApiMng.post(
    `${controllerName}/Start`,
    params,
  );
};

const stopProcess = ({ controllerName, id }) => {
  return aeroApiMng.post(`${controllerName}/Stop`, {
    id,
  });
};

const getInfo = ({ controllerName, id }) => {
  return aeroApiMng.get(`${controllerName}/Info`, {
    params: { id },
  });
};

const getLog = ({ controllerName, id }) => {
  return aeroApiMng.get(`${controllerName}/Log`, {
    params: { id },
  });
};

const getList = ({ controllerName }) => {
  return aeroApiMng.get(`${controllerName}/List`);
};

const downloadFile = ({ controllerName, id }) => {
  return aeroApiMng.get(`${controllerName}/File`, {
    params: { id },
    responseType: 'blob',
  });
};

export default {
  startProcess,
  stopProcess,
  getInfo,
  getLog,
  getList,
  downloadFile,
};
