import longProcessServiceApi from '@/api/longProcessService';
import downloadHelpers from '@/utils/downloadHelpers.js';

const state = {
  info: {
    isLoading: false,
    data: [],
    errors: null,
  },

  list: {
    isLoading: false,
    data: [],
    errors: null,
  },
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].errors = error;
  },
};

const actions = {
  startProcess(context, requestData) {
    return longProcessServiceApi
      .startProcess(requestData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },

  stopProcess(context, requestData) {
    return longProcessServiceApi
      .stopProcess(requestData)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  },

  getInfo({ commit }, requestData) {
    commit('SET_LOADING', { key: 'info', status: true });
    return longProcessServiceApi
      .getInfo(requestData)
      .then((res) => {
        commit('SET_DATA', { key: 'info', data: res.data });
        return res.data;
      })
      .catch((err) => {
        commit('SET_ERROR', { key: 'info', err: err });
        throw err;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'info', status: false });
      });
  },

  getLog(context, payload) {
    return longProcessServiceApi
      .getLog(payload)
      .then((response) => response.data);
  },

  getList({ commit }, requestData) {
    commit('SET_LOADING', { key: 'list', status: true });
    return longProcessServiceApi
      .getList(requestData)
      .then((res) => {
        commit('SET_DATA', { key: 'list', data: res.data });
        return res.data;
      })
      .catch((err) => {
        commit('SET_ERROR', { key: 'list', err: err });
        throw err;
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'list', status: false });
      });
  },

  downloadFile(context, requestData) {
    longProcessServiceApi
      .downloadFile(requestData)
      .then((response) => {
        downloadHelpers(response.data, response.headers['content-disposition']);
      })
      .catch((err) => console.error(err));
  },
};

export default {
  name: 'longProcessService',
  namespaced: true,
  state,
  mutations,
  actions,
};
