import { aeroApiMng } from '@/plugins/axios.js';

const getByMonth = (data) => {
  return aeroApiMng.post(
    `/EmploymentCalendar/Month`,
    data,
  );
};

const saveByMonth = (data) => {
  return aeroApiMng.post(
    `/EmploymentCalendar/SaveMonth`,
    data,
  );
};

export default {
  getByMonth,
  saveByMonth,
};
